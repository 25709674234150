import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Container, Typography, Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Swiper, SwiperSlide } from 'components/Shared/Swiper';
import MediaPressContent from 'components/MediaPressComponent/MediaPressContent';

import { mediaPress } from 'mocks/cms/mediaPress';

MediaPressComponent.propTypes = {
  showHeadline: PropTypes.bool,
};

MediaPressComponent.defaultProps = {
  showHeadline: false,
};

export default function MediaPressComponent({ showHeadline }) {
  const swiperRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Box
      bgcolor="#212121"
      color="primary.contrastText"
      id="mediapress"
      py={2}
      textAlign="center"
    >
      <Container maxWidth="md">
        <Grid container alignItems="center" spacing={{ xs: 3, sm: 0 }}>
          {showHeadline && (
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant="body2" component="h6">
                  Fluencypass na mídia
                </Typography>
              </Box>
            </Grid>
          )}

          {isMobile ? (
            <>
              <Grid item xs={10}>
                <Swiper
                  navigation={{ nextEl: '.next-button' }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  isPrimaryColor={false}
                  alternativeColor="#FFFFFF"
                  slidesPerView={3}
                >
                  {mediaPress.map((media, index) => (
                    <SwiperSlide key={`media-${index}`}>
                      <MediaPressContent media={media} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid className="next-button" onClick={handleNext} item xs={1}>
                <ArrowForwardIosIcon fontSize="large" />
              </Grid>
            </>
          ) : (
            mediaPress.map((media) => (
              <Grid item xs={6} sm={3} key={`${media.name}-key`}>
                <MediaPressContent media={media} />
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </Box>
  );
}
